import { Splide } from "@splidejs/splide";

class FilteredFeatured extends HTMLElement {
  constructor() {
    super();

    this.dropdowns = this.querySelectorAll("dropdown-selector");
    this.button = this.querySelector('button[type="submit"]');
    this.reset = this.querySelector('button[type="reset"]');
    this.collectionUrl = this.getAttribute("collection-url");
    this.productCarousel = this.querySelector("#filtered-product-grid");
    this.isLoading = false;

    this.carousel = new Splide(this.productCarousel, {
      arrows: true,
      pagination: false,
      perPage: 4,
      gap: "60px",
      arrowPath:
        "M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z",
      classes: {
        arrows: "splide__arrows ",
        arrow: "splide__arrow h-10 w-10 rounded-none opacity-100 top-1/3",
        prev: "-left-4 splide__arrow--prev",
        next: "-right-4 splide__arrow--next",
      },
      breakpoints: {
        1024: {
          pagination: true,
          perPage: 1,
          classes: {
            pagination:
              "splide__pagination filter-carousel__pagination -bottom-10",
            page: "filter-carousel-page",
          },
        },
      },
    });
  }

  connectedCallback() {
    this.button.addEventListener("click", this.onFilterSubmit.bind(this));
    this.reset.addEventListener("click", this.onResetSubmit.bind(this));
  }

  onResetSubmit() {
    this.productCarousel.innerHTML = "";

    //swap buttons
    this.button.classList.toggle("hidden");
    this.reset.classList.toggle("hidden");

    //reset carousel
    this.productCarousel.classList.add("opacity-0", "hidden");
    this.productCarousel.classList.remove("opacity-100");

    for (let dropdown of this.dropdowns) {
      const select = dropdown.querySelector("select");
      select.selectedIndex = -1;
      var event = new Event("change");
      dropdown.dispatchEvent(event);
    }
  }

  async onFilterSubmit() {
    let filtersToSubmit = [];
    //see if all filters are selected
    for (let dropdown of this.dropdowns) {
      const value = dropdown.querySelector("select").value;
      if (value != "") {
        filtersToSubmit.push(value);
        continue;
      }
    }

    //if they are construct the url and fetch products.
    if (!this.isLoading) {
      let url = this.collectionUrl + "?" + filtersToSubmit.join("&");
      let initialButton = this.button.textContent;
      try {
        this.isLoading = true;
        this.button.textContent = "Loading Results";
        let response = await fetch(url);
        if (!response.ok) throw new Error(response.status);
        let text = await response.text();

        let htmlMarkup = new DOMParser()
          .parseFromString(text, "text/html")
          .querySelector(".js-product-grid");

        //if there are more than 1 products create a carousel 1 is for mobile purposes, no need to create a carousel for 1 product
        if (htmlMarkup.childElementCount > 1) {
          const track = document.createElement("div");
          track.classList.add("splide__track");
          const list = document.createElement("div");
          list.classList.add("splide__list");
          list.innerHTML = htmlMarkup.innerHTML;
          track.append(list);
          this.productCarousel.append(track);
          this.productCarousel.classList.add("splide");

          this.carousel.mount();
        } else {
          const container = document.createElement("div");
          container.classList.add(
            "grid",
            "lg:grid-cols-4",
            "gap-[60px]",
            "grid-cols-1"
          );
          container.innerHTML = htmlMarkup.innerHTML;
          this.productCarousel.append(container);
        }
      } catch (err) {
        console.error(err);
      }

      // This code is only excecuted if the above doesn't throw an error.
      this.productCarousel.classList.remove("hidden");
      //reveal carousel
      setTimeout(() => {
        this.productCarousel.classList.remove("opacity-0");
        this.productCarousel.classList.add("opacity-100");
      }, 100);

      //swap filter & reset buttons
      this.button.textContent = initialButton;
      this.button.classList.toggle("hidden");
      this.reset.classList.toggle("hidden");
      this.carousel.refresh();
      this.isLoading = false;
    }
  }
}

if (!window.customElements.get("filtered-featured")) {
  window.customElements.define("filtered-featured", FilteredFeatured);
}
