import Splide from '@splidejs/splide';

class MobileCarousel extends HTMLElement {
  constructor() {
    super();

    this.slides = this.innerHTML;
    this.carousel = new Splide(this, {
      arrows: true,
      pagination: false,
      perPage: 1,
      perMove: 1,
      arrowPath:
        'M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z',
      classes: {
        arrows: 'splide__arrows absolute w-full top-[40%] left-0',
        arrow: 'splide__arrow h-10 w-10 rounded-none opacity-100',
        prev: 'left-0 splide__arrow--prev',
        next: 'right-0 splide__arrow--next'
      },
      mediaQuery: 'min',
      breakpoints: {
        1024: {
          destroy: true
        }
      }
    });
  }

  connectedCallback() {
    this.generateCarousel();
  }

  generateCarousel() {
    this.innerHTML = '';
    const list = document.createElement('div');
    list.classList.add(
      'splide__list',
      'lg:!grid',
      'lg:grid-cols-3',
      'lg:grid-rows-1',
      'lg:w-max',
      'lg:gap-x-20',
      'lg:!mx-auto'
    );

    const track = document.createElement('div');
    track.classList.add('splide__track', 'lg:w-full');

    list.innerHTML = this.slides;

    track.append(list);
    this.append(track);

    this.carousel.mount();
  }
}

if (!window.customElements.get('mobile-carousel')) {
  window.customElements.define('mobile-carousel', MobileCarousel);
}
