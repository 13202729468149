class HeaderDrawer extends HTMLElement {
  constructor() {
    super();

    this.header = this.closest('header');
    this.details = this.querySelector('details');
    this.parentDetails = this.details.querySelectorAll('details');
  }

  connectedCallback() {
    this.details
      .querySelector('summary')
      .addEventListener('click', this.onToggle.bind(this));
    this.parentDetails.forEach((detail) => {
      detail
        .querySelector('summary')
        .addEventListener('click', this.onToggle.bind(this));
    });
  }

  drawerOpenAnimation(
    target,
    fast,
    animationClass = ['lg:animate-left-right']
  ) {
    const onAnimationEnd = (cb) =>
      target
        .querySelector('header-drawer-group')
        .addEventListener('animationend', cb, { once: true });

    const overlay = target
      .querySelector('summary')
      .querySelector('#drawer-overlay');

    if (fast) {
      target.open = true;
    } else {
      requestAnimationFrame(() => {
        target
          .querySelector('header-drawer-group')
          .classList.add(...animationClass);
        if (overlay) overlay.classList.add('lg:!opacity-40');
      });

      onAnimationEnd(() => {
        target
          .querySelector('header-drawer-group')
          .classList.remove(...animationClass);
        if (overlay) overlay.classList.remove('lg:!opacity-40');
      });

      target.open = true;
    }
  }

  drawerCloseAnimation(
    target,
    fast = false,
    animationClass = ['lg:animate-right-left']
  ) {
    const overlay = target
      .querySelector('summary')
      .querySelector('#drawer-overlay');

    const onAnimationEnd = (cb) =>
      target
        .querySelector('header-drawer-group')
        .addEventListener('animationend', cb, { once: true });

    if (fast) {
      target.open = false;
    } else {
      target
        .querySelector('header-drawer-group')
        .classList.add(...animationClass);
      if (overlay) overlay.classList.add('lg:opacity-0');

      onAnimationEnd(() => {
        target.open = false;
        target
          .querySelector('header-drawer-group')
          .classList.remove(...animationClass);
        target.querySelectorAll('details').forEach((child) => {
          child.open = false;
        });
        if (overlay) overlay.classList.remove('lg:opacity-0');
      });
    }
  }

  onToggle(event) {
    //on closeure of target details, close all child details & sibling details
    event.preventDefault();
    const target = event.target.closest('details');
    const isOpen = target.hasAttribute('open');
    const details = target
      .closest('header-drawer-group')
      .querySelectorAll('details');

    let fastOpen = false;
    fastOpen = [...details].some((child) => child.hasAttribute('open'));

    const openCls = ['lg:animate-left-right', 'max-[600px]:animate-top-bottom'];
    const closeCls = [
      'lg:animate-right-left',
      'max-[600px]:animate-bottom-top'
    ];

    if (isOpen) {
      if (target.classList.contains('group/drawer')) {
        this.drawerCloseAnimation(target, false, ['animate-right-left']);
      } else {
        this.drawerCloseAnimation(target, false, closeCls);
      }
    } else {
      details.forEach((child) => {
        // if target is drawer and there are no other details open - open first child as well.
        if (target.classList.contains('group/drawer')) {
          if (
            child === target.querySelector('details') &&
            ![...details].some((child) => child.open && child !== target)
          ) {
            this.drawerOpenAnimation(child, true, openCls);
          }
          if (child === target) {
            this.drawerOpenAnimation(child, false, ['animate-left-right']);
          }
        } else {
          if (child === target) {
            this.drawerOpenAnimation(child, fastOpen, openCls);
          } else {
            if(target.contains(child)){
            
            } else {
              this.drawerCloseAnimation(child, true, closeCls);
            }
          }
        }
      });
    }
  }
}

if (!window.customElements.get('header-drawer')) {
  window.customElements.define('header-drawer', HeaderDrawer);
}

/* Header BG */
// Adjust this value based on the desired virtual margin
const virtualMargin = 10;

const applyHoverState = () => {
  const header = document.querySelector('header');
  const headerBG = document.querySelector('#headerBG');
  const headerHeight = header.offsetHeight;
  const scrollPosition = window.scrollY;

  if (header.classList.contains('transparent')) {
    if (scrollPosition > headerHeight + virtualMargin) {
      headerBG.classList.add('transition-opacity', '!opacity-100');
    } else {
      headerBG.classList.remove('!opacity-100');
    }
  } else {
    headerBG.classList.add('transition-opacity', '!opacity-100');
  }
};

window.addEventListener('scroll', () => {
  applyHoverState();
});

document.addEventListener('DOMContentLoaded', (event) => {
  applyHoverState();
});

/* Header Buttons */
const header = document.querySelector('header');
const drawerToggleButtons = header.querySelectorAll(
  'button[aria-controls="link-list"]'
);

drawerToggleButtons.forEach((toggle) => {
  toggle.addEventListener('click', (event) => {
    // Get the target content element
    const targetContent = header.querySelector(`#${toggle.id}`);
    const targetChild = targetContent.querySelector('details');

    // Traverse up the DOM to find all ancestor <details> elements
    let element = targetContent;
    if(targetChild) element = targetChild;
   
    // Function to open a details element by simulating a click on its summary
    function openDetails(detailsElement) {
      if (!detailsElement.open) {
        const summary = detailsElement.querySelector('summary');
        if(summary){
          summary.click();
        }
      }
    }

    // Traverse up the DOM tree and open each ancestor <details> element
    while (element && element.tagName !== 'BODY') {
      if (element.tagName === 'DETAILS') {
        openDetails(element);
      }
      element = element.parentElement;
    }
  });
});
